/* 모달창을 화면 중앙. 최상단에 노출 */
.container {
  /* 모달창 크기 */
  width: 426px;
  height: 260px;
  /* 최상단 위치 */
  z-index: 99;
  /* 중앙 배치 */
  position: fixed;
  display: flex;
  top: 50%;
  left: 50%;
  justify-content: center;
  transform: translate(-50%, -50%);
  /* 모달창 디자인 */
  border-radius: 12px;
  border: 0.5px solid var(--line, #D5D5D5);
  background: #FFF;
  box-shadow: 0px 0px 4px 0px rgba(63, 63, 63, 0.18);
}
/* 모달창 내부 X버튼 */
.close {
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 10px;
}
.exclamationCircle {
  position: relative;
  box-sizing: border-box;
  top:56px;
  width: 40px;
  height: 40px;
  color: #A7A7A7;
}