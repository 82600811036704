.main {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-height: calc(100% - 80px - 238px);
  padding-bottom: 120px;
  background-color: white;
}

.content {
  width: 100%;
  max-width: 1260px;
}
